dl {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0
}
dt {
    float: left;
    width: 30%;
    text-transform: uppercase;
    text-align: right;
    font-weight: bold;
    /* adjust the width; make sure the total of both is 100% */
    padding: 0 2rem;
    margin: 0;
    /* margin-right: 2em; */
}
dd {
    float: left;
    width: 70%;
    /* adjust the width; make sure the total of both is 100% */
    padding: 0;
    margin: 0;
    /*margin-left: 2em;*/
    clear: right;
}

.archive .archive-item-date {
    width: 8em;
}

// .fig-right{
//     margin-right: 0;
//     /* display: flex; */
//     float: right;
// }